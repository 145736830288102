var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.$vuetify.breakpoint.smAndDown ? "mt-8" : ""] },
    [
      _c(
        "section",
        { staticClass: "col-12", attrs: { id: "hero" } },
        [
          _c(
            "v-row",
            { staticClass: "justify-center" },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "col-xs-12 col-md-6 left-col mt-xs-1 order-2 order-md-1 ",
                },
                [
                  _c(
                    "div",
                    [
                      _c("h1", [
                        _vm._v(" " + _vm._s(_vm.$t("CarRepaired")) + " "),
                      ]),
                      _c("small", { staticClass: "information d-block mt-2" }, [
                        _vm._v(_vm._s(_vm.$t("AutoShopExperience"))),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "mt-md-12 mt-7 white--text auto-estimate-btn text-none mobile-btn",
                          attrs: {
                            color: "#12945F",
                            height: "48",
                            elevation: "0",
                            to: { name: "AutoEstimate" },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("GetAnEstimate")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "d-block mt-1 tip-below-button" },
                    [
                      _c("v-img", {
                        attrs: {
                          width: "172",
                          src: require("../assets/images/google-review.png"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "pt-10" }, [_c("AsSeenOn")], 1),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "col-xs-12 col-md-6 order-1 order-md-2 mt-md-12 pl-1 pr-0",
                },
                [
                  _c("v-img", {
                    staticClass: "mt-12",
                    attrs: {
                      "lazy-src": require("../assets/images/ucar-mobile-mechanic.webp"),
                      src: require("../assets/images/ucar-mobile-mechanic.webp"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass:
            "justify-space-around hidden-xs-only justify-md-space-between pa-5 px-5 px-md-16",
          attrs: { id: "service-description-rectangle" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../assets/icons/serviceFeature/hexagon.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("MonthWarranty")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-sm-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../assets/icons/serviceFeature/triangle.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("CertifiedMechanics")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-md-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../assets/icons/serviceFeature/square.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("EasyConvenient")) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-lg-flex" },
            [
              _c("v-img", {
                attrs: {
                  width: "20",
                  height: "20",
                  src: require("../assets/icons/serviceFeature/circle.svg"),
                },
              }),
              _c(
                "span",
                {
                  staticClass:
                    "ml-3 service-feature-description align-self-center",
                },
                [_vm._v(" " + _vm._s(_vm.$t("ReliableAffordable")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.$vuetify.breakpoint.xsOnly
        ? _c(
            "v-layout",
            {
              staticClass:
                "justify-space-around justify-md-space-between pa-5 px-5 px-md-16",
              attrs: { id: "feature-carousel-description" },
            },
            [
              _c(
                "v-carousel",
                {
                  attrs: {
                    "show-arrows": false,
                    "show-arrows-on-hover": false,
                    "hide-delimiters": true,
                    cycle: "",
                    height: "64",
                  },
                },
                _vm._l(_vm.serviceFeatures, function (feature, i) {
                  return _c(
                    "v-carousel-item",
                    { key: i, attrs: { transition: "fade-transition" } },
                    [
                      _c("v-layout", [
                        _c(
                          "div",
                          { staticClass: "d-flex mx-auto" },
                          [
                            _c("v-img", {
                              attrs: {
                                width: "20",
                                height: "20",
                                src: require("../assets/icons/serviceFeature/" +
                                  feature.pathImage),
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "ml-3 service-feature-description align-self-center",
                              },
                              [_vm._v(" " + _vm._s(feature.description) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }