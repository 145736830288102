var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "subscribeContainer" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "col-12 col-md-6 align-self-center pt-0" },
                [
                  _c("h2", { staticClass: "mx-auto ml-0 mb-0" }, [
                    _vm._v(" " + _vm._s(_vm.$t("NoNeedCarRepairNow")) + " "),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "mx-auto",
                      attrs: { id: "subscribeSubtitle" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("WeGetIt")) + " ")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "col-12 col-md-6 align-self-center pt-0" },
                [
                  _c(
                    "div",
                    { staticClass: "col-md-10 pt-0 px-0" },
                    [
                      _c(
                        "v-form",
                        { ref: "subscribeForm" },
                        [
                          _c("p", { attrs: { id: "receiveUpdates" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("SubscribeToUpdate")) + " "
                            ),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              placeholder: _vm.$t("FirstNameWithoutStar"),
                              "background-color": "white",
                              outlined: "",
                              rules: [
                                function () {
                                  return (
                                    !!_vm.firstName || _vm.$t("FieldRequired")
                                  )
                                },
                              ],
                            },
                            model: {
                              value: _vm.firstName,
                              callback: function ($$v) {
                                _vm.firstName = $$v
                              },
                              expression: "firstName",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "pr-0",
                            attrs: {
                              placeholder: "example@domain.com",
                              "background-color": "white",
                              outlined: "",
                              rules: [
                                function () {
                                  return (
                                    !!_vm.emailAddress ||
                                    _vm.$t("FieldRequired")
                                  )
                                },
                              ],
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-fade-transition",
                                      { attrs: { "leave-absolute": "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "text-none font-weight-bold",
                                            attrs: {
                                              elevation: "0",
                                              color: "primary",
                                              height: "56",
                                              loading: _vm.subscribeLoading,
                                              width: _vm.$vuetify.breakpoint
                                                .mdAndUp
                                                ? 168
                                                : 120,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.subscribe()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("Subscribe")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.emailAddress,
                              callback: function ($$v) {
                                _vm.emailAddress = $$v
                              },
                              expression: "emailAddress",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }