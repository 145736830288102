var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "car-make-model" } },
    [
      _c(
        "v-row",
        { staticClass: "col-12 col-md-8 mx-auto" },
        [
          _c("v-col", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "mx-auto" }, [
              _vm._v(_vm._s(_vm.$t("CarMakeModel"))),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "col-12 col-md-10 mx-auto mb-2" },
        _vm._l(_vm.makes, function (make, index) {
          return _c(
            "v-col",
            { key: index, staticClass: "col-3 col-md-2" },
            [
              _c("v-img", {
                staticClass: "text-right mt-0",
                attrs: {
                  loading: "lazy",
                  "lazy-src": require("../assets/images/makes/" +
                    make +
                    ".webp"),
                  src: require("../assets/images/makes/" + make + ".webp"),
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }