// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/testimonials-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#testimonials[data-v-1f3609e4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: repeat;\n  min-height: 798px;\n}\n#testimonials h2[data-v-1f3609e4] {\n  font-family: Merriweather !important;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 2.25rem;\n  line-height: 130%;\n  text-align: center;\n  letter-spacing: -1px;\n  text-transform: capitalize;\n  padding-top: 156px;\n  color: #fff;\n  max-width: 476px;\n  margin: auto;\n}\n@media (min-width: 291px) and (max-width: 767px) {\n#testimonials h2[data-v-1f3609e4] {\n    padding-top: 112px;\n    font-size: 1.875rem;\n    letter-spacing: -0.5px;\n    padding-left: 20px;\n    padding-right: 20px;\n}\n}\n#testimonials .testimonial-card[data-v-1f3609e4] {\n  width: 33.333%;\n  max-height: 308px;\n}\n#testimonials .card-text[data-v-1f3609e4] {\n  padding: 20px 8px 0px 20px;\n  font-family: rubik !important;\n  font-style: normal;\n  font-weight: 300;\n  font-size: 1.125rem;\n  line-height: 150%;\n  color: #000;\n}\n#testimonials .card-text-bold[data-v-1f3609e4] {\n  font-weight: bold;\n  font-family: Merriweather !important;\n}", ""]);
// Exports
module.exports = exports;
