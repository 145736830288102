var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ucarmobile-features-container" } },
    [
      _c(
        "v-row",
        { staticClass: "col-12 col-md-10 mx-auto" },
        _vm._l(_vm.features, function (feature, index) {
          return _c(
            "v-col",
            { key: index, staticClass: "col-12 col-md-4" },
            [
              _c("simple-text-card", {
                attrs: {
                  title: feature.title,
                  description: feature.description,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-layout",
        { staticClass: "mx-auto mb-1 justify-center mt-10" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-none white--text",
              staticStyle: { "font-family": "Rubik" },
              attrs: {
                elevation: "0",
                height: "48",
                "min-width": "334",
                color: "rgb(18, 148, 95)",
                to: { name: "AutoEstimate" },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("OverHundredServices")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }