var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { attrs: { id: "testimonials" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "col-md-11 mx-auto" },
                [
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "title-section header-title text-capitalize",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Testimonials.Title")) + " ")]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "justify-space-around" },
                    _vm._l(_vm.testimonials, function (testimonial, index) {
                      return _c(
                        "v-col",
                        { key: index, staticClass: "col-md-4 mx-auto" },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "mx-auto testimonial-card",
                              attrs: {
                                "min-height": "384",
                                width: _vm.$vuetify.breakpoint.smAndUp
                                  ? "408px"
                                  : "348px",
                              },
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c("v-col", [
                                        _c(
                                          "div",
                                          { staticClass: "card-text " },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "mb-1" },
                                              _vm._l(
                                                testimonial.rate,
                                                function (index) {
                                                  return _c(
                                                    "v-icon",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        large: "",
                                                        color: "#12945F",
                                                      },
                                                    },
                                                    [_vm._v(" mdi-star ")]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(testimonial.comment) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "card-text card-text-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(testimonial.author) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }