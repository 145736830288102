<template>
  <main>
    <hero></hero>
<!--    <as-featured-in></as-featured-in>-->
    <mobile-services-catalog></mobile-services-catalog>
    <auto-service-description></auto-service-description>
    <ucar-mobile-features></ucar-mobile-features>
    <how-it-works></how-it-works>
    <cities-we-serve></cities-we-serve>
    <testimonials></testimonials>
    <subscribe-for-updates></subscribe-for-updates>
    <car-make-model></car-make-model>
    <cars-we-service :display-city-name="false"></cars-we-service>
  </main>
</template>

<script>
import MobileServicesCatalog from '@/components/MobileServicesCatalog.vue';
import AutoServiceDescription from '@/components/AutoServiceDescription.vue';
import UcarMobileFeatures from '@/components/UcarMobileFeatures.vue';
import HowItWorks from '@/components/HowItWorks.vue';
import Hero from '@/components/Hero.vue';
import CitiesWeServe from '@/components/CitiesWeServe.vue';
import Testimonials from '@/components/Testimonials.vue';
// import AsFeaturedIn from '@/components/AsFeaturedIn.vue';
import SubscribeForUpdates from '@/components/SubscribeForUpdates.vue';
import CarsWeService from '@/components/city/CarsWeService.vue';
import CarMakeModel from '@/components/CarMakeModel.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    // AsFeaturedIn,
    MobileServicesCatalog,
    AutoServiceDescription,
    UcarMobileFeatures,
    SubscribeForUpdates,
    HowItWorks,
    CitiesWeServe,
    Testimonials,
    CarsWeService,
    CarMakeModel,
  },
  metaInfo: {
    title: 'UcarMobile: Auto Repair by Best Mobile Car Mechanics',
    meta: [
      {
        name: 'description',
        content: 'Best mobile car repair service near you. Easy and convenient. Reliable and trustworthy mobile auto repair. Get an instant estimate. All makes and models.',
      },
      {
        name: 'keywords',
        content: 'auto repair, mobile mechanic, find local mechanics, mobile car repair, car repair, find mechanic, mechanic, auto shop, auto repair shop, car mechanic',
      },
    ],
  },
};
</script>
