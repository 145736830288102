var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "col-12 col-md-8 mx-auto auto-service",
      attrs: { id: "auto-service" },
    },
    [
      _c(
        "v-col",
        { staticClass: "col-12 col-md-6" },
        [
          _c("v-img", {
            staticClass: "mt-12 text-right mx-auto mx-md-0",
            attrs: {
              id: "about-us-illustration",
              "max-width": "310px",
              contain: "",
              height: _vm.$vuetify.breakpoint.smAndDown ? 350 : 445,
              "lazy-src": require("../assets/images/mobile-mechanic.webp"),
              src: require("../assets/images/mobile-mechanic.webp"),
            },
          }),
        ],
        1
      ),
      _c("v-col", { staticClass: "col-12 col-md-6 mt-9" }, [
        _c("h2", { staticClass: "mt-5 mb-4" }, [
          _vm._v(" " + _vm._s(_vm.$t("AutoService.Title")) + " "),
        ]),
        _c("p", { staticClass: "subTitle" }, [
          _vm._v(" " + _vm._s(_vm.$t("AutoService.SubTitle")) + " "),
        ]),
        _c("p", { staticClass: "description" }, [
          _vm._v(" " + _vm._s(_vm.$t("AutoService.Description.P1")) + " "),
        ]),
        _c("p", { staticClass: "description" }, [
          _vm._v(" " + _vm._s(_vm.$t("AutoService.Description.P2")) + " "),
        ]),
        _c("p", { staticClass: "description" }, [
          _vm._v(" " + _vm._s(_vm.$t("AutoService.Description.P3")) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }